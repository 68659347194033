<template>
    <div class="main-container">
        <div class="main-wrapper">
            <div class="search-wrapper">
                <el-input placeholder="请输入您要搜索的商品、类目或链接" v-model="searchInput" class="search-input">
                    <div slot="append" class="search-btn" @click="searchBtn">搜索</div>
                </el-input>
                <div class="history-keys">
                    <!--                    点击之后直接搜索-->
                    <span class="keys-item"
                          @click="chooseSearchKeys(keysItem)"
                          :class="keysItem.hot ? 'current' : ''"
                          v-for="(keysItem, keysIndex) in historyList" :key="`historyList_${keysIndex}`">{{keysItem.keys}}</span>
                </div>
            </div>
            <div class="list-wrapper">
                <div class="list-tab">
                    <div class="tab-item" :class="currentTab === 1 ? 'current' : ''" @click="toggleTab(1)">推荐榜</div>
                    <div class="tab-item" :class="currentTab === 2 ? 'current' : ''" @click="toggleTab(2)">热销榜</div>
                    <div class="tab-item" :class="currentTab === 3 ? 'current' : ''" @click="toggleTab(3)">高佣大额券</div>
                    <div class="tab-item" :class="currentTab === 4 ? 'current' : ''" @click="toggleTab(4)">全部商品</div>
                </div>
                <el-scrollbar class="list-container">
                    <div class="list-content" v-if="currentTab === 1">
                        <div class="good-item" v-for="(goodItem, goodIndex) in goodsList" :key="`goodsList_${goodIndex}`">
                            <div class="good-rank">
                                <i class="iconfont">&#xe6b2;</i>
                                <div class="top">TOP</div>
                                <div class="num">{{goodItem.rank}}</div>
                            </div>
                            <div class="good-cover">
                                <img :src="goodItem.goodCover" alt="">
                                <div class="good-operate">
                                    <div class="o-item gray text-overflow" v-if="goodItem.addStatus" @click="addSingle(goodItem)">已放入选品库</div>
                                    <div class="o-item text-overflow" @click="addSingle(goodItem)" v-else>放入选品库</div>
                                    <router-link :to="{name: 'studentImmediatePromotion', query: {id: goodItem.id}}" class="o-item text-overflow">立即推广</router-link>
                                </div>
                            </div>
                            <div class="good-detail">
                                <a :href="goodItem.goodLink" target="_blank" class="good-name text-overflow-2">{{goodItem.goodName}}</a>
                                <div class="good-sale">2小时销量<span class="orange">{{goodItem.sales}}</span>笔</div>
                                <el-progress class="good-process" :percentage="goodItem.commissionRate" :show-text="false" color="#FF5200"></el-progress>
                                <div class="good-line">
                                    <div class="l-item">
                                        <div class="line1 orange">{{goodItem.price.toFixed(2)}}</div>
                                        <div class="line2">券后</div>
                                    </div>
                                    <div class="l-item">
                                        <div class="line1 orange">{{goodItem.commissionRate}}%</div>
                                        <div class="line2">营销</div>
                                    </div>
                                    <div class="l-item">
                                        <div class="line1 orange">{{(goodItem.price * goodItem.commissionRate * 0.01).toFixed(2)}}</div>
                                        <div class="line2">佣金</div>
                                    </div>
                                </div>
                                <div class="good-ticket">
                                    <div class="ticket-left">券</div>
                                    <div class="right">{{goodItem.vouchers}}元</div>
                                </div>
                                <div class="store-name">{{goodItem.storeName}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="list-content" v-if="currentTab === 2">
                        <div class="good-item" v-for="(goodItem, goodIndex) in hotGoodsList" :key="`goodsList_${goodIndex}`">
                            <div class="good-rank">
                                <i class="iconfont">&#xe6b2;</i>
                                <div class="top">TOP</div>
                                <div class="num">{{goodItem.rank}}</div>
                            </div>
                            <div class="good-cover">
                                <img :src="goodItem.goodCover" alt="">
                                <div class="good-operate">
                                    <div class="o-item gray text-overflow" v-if="goodItem.addStatus">已放入选品库</div>
                                    <div class="o-item text-overflow" v-else>放入选品库</div>
                                    <div class="o-item text-overflow">立即推广</div>
                                </div>
                            </div>
                            <div class="good-detail">
                                <a :href="goodItem.goodLink" target="_blank" class="good-name text-overflow-2">{{goodItem.goodName}}</a>
                                <div class="good-sale">2小时销量<span class="orange">{{goodItem.sales}}</span>笔</div>
                                <el-progress class="good-process" :percentage="goodItem.commissionRate" :show-text="false" color="#FF5200"></el-progress>
                                <div class="good-line">
                                    <div class="l-item">
                                        <div class="line1 orange">{{goodItem.price.toFixed(2)}}</div>
                                        <div class="line2">券后</div>
                                    </div>
                                    <div class="l-item">
                                        <div class="line1 orange">{{goodItem.commissionRate}}%</div>
                                        <div class="line2">营销</div>
                                    </div>
                                    <div class="l-item">
                                        <div class="line1 orange">{{(goodItem.price * goodItem.commissionRate * 0.01).toFixed(2)}}</div>
                                        <div class="line2">佣金</div>
                                    </div>
                                </div>
                                <div class="good-ticket">
                                    <div class="ticket-left">券</div>
                                    <div class="right">{{goodItem.vouchers}}元</div>
                                </div>
                                <div class="store-name">{{goodItem.storeName}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="list-content" v-if="currentTab === 3">
                        <div class="good-item" v-for="(goodItem, goodIndex) in highCommissionList" :key="`goodsList_${goodIndex}`">
                            <div class="good-rank">
                                <i class="iconfont">&#xe6b2;</i>
                                <div class="top">TOP</div>
                                <div class="num">{{goodItem.rank}}</div>
                            </div>
                            <div class="good-cover">
                                <img :src="goodItem.goodCover" alt="">
                                <div class="good-operate">
                                    <div class="o-item gray text-overflow" v-if="goodItem.addStatus">已放入选品库</div>
                                    <div class="o-item text-overflow" v-else>放入选品库</div>
                                    <div class="o-item text-overflow">立即推广</div>
                                </div>
                            </div>
                            <div class="good-detail">
                                <a :href="goodItem.goodLink" target="_blank" class="good-name text-overflow-2">{{goodItem.goodName}}</a>
                                <div class="good-sale">2小时销量<span class="orange">{{goodItem.sales}}</span>笔</div>
                                <el-progress class="good-process" :percentage="goodItem.commissionRate" :show-text="false" color="#FF5200"></el-progress>
                                <div class="good-line">
                                    <div class="l-item">
                                        <div class="line1 orange">{{goodItem.price.toFixed(2)}}</div>
                                        <div class="line2">券后</div>
                                    </div>
                                    <div class="l-item">
                                        <div class="line1 orange">{{goodItem.commissionRate}}%</div>
                                        <div class="line2">营销</div>
                                    </div>
                                    <div class="l-item">
                                        <div class="line1 orange">{{(goodItem.price * goodItem.commissionRate * 0.01).toFixed(2)}}</div>
                                        <div class="line2">佣金</div>
                                    </div>
                                </div>
                                <div class="good-ticket">
                                    <div class="ticket-left">券</div>
                                    <div class="right">{{goodItem.vouchers}}元</div>
                                </div>
                                <div class="store-name">{{goodItem.storeName}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="list-content" v-if="currentTab === 4">
                        <div class="good-item" v-for="(goodItem, goodIndex) in allGoodsList" :key="`goodsList_${goodIndex}`">
                            <div class="good-rank">
                                <i class="iconfont">&#xe6b2;</i>
                                <div class="top">TOP</div>
                                <div class="num">{{goodItem.rank}}</div>
                            </div>
                            <div class="good-cover">
                                <img :src="goodItem.goodCover" alt="">
                                <div class="good-operate">
                                    <div class="o-item gray text-overflow" v-if="goodItem.addStatus">已放入选品库</div>
                                    <div class="o-item text-overflow" v-else>放入选品库</div>
                                    <div class="o-item text-overflow">立即推广</div>
                                </div>
                            </div>
                            <div class="good-detail">
                                <a :href="goodItem.goodLink" target="_blank" class="good-name text-overflow-2">{{goodItem.goodName}}</a>
                                <div class="good-sale">2小时销量<span class="orange">{{goodItem.sales}}</span>笔</div>
                                <el-progress class="good-process" :percentage="goodItem.commissionRate" :show-text="false" color="#FF5200"></el-progress>
                                <div class="good-line">
                                    <div class="l-item">
                                        <div class="line1 orange">{{goodItem.price.toFixed(2)}}</div>
                                        <div class="line2">券后</div>
                                    </div>
                                    <div class="l-item">
                                        <div class="line1 orange">{{goodItem.commissionRate}}%</div>
                                        <div class="line2">营销</div>
                                    </div>
                                    <div class="l-item">
                                        <div class="line1 orange">{{(goodItem.price * goodItem.commissionRate * 0.01).toFixed(2)}}</div>
                                        <div class="line2">佣金</div>
                                    </div>
                                </div>
                                <div class="good-ticket">
                                    <div class="ticket-left">券</div>
                                    <div class="right">{{goodItem.vouchers}}元</div>
                                </div>
                                <div class="store-name">{{goodItem.storeName}}</div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <el-pagination class="circle-page" style="margin: 20px 0"
                           :current-page.sync="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="pagesCurrentChange">
            </el-pagination>
        </div>
        <div class="operate-wrapper">
            <el-checkbox class="checkAll" v-model="checkedAll" @change="checkedAllBtn">选取全页商品</el-checkbox>
            <div class="checkedNum">已选取{{chooseIdArr.length}}/180个普通商品</div>
            <div>
                <router-link to="/student/mySelectionLibrary" class="bigBtn blue">我的选品库</router-link>
                <div class="bigBtn orange" @click="addProductsLibrary">加入选品库</div>
            </div>
        </div>

        <el-dialog title="选择分组" :visible.sync="dialogChooseGroup" width="662px" custom-class="customDialog" @close="closeChooseGroupDialog">
            <div class="group-container">
                <div class="create-group">
                    <el-input placeholder="输入分组名称1-20字"
                              size="medium"
                              v-model="groupName"
                              class="group-name" style="width: 260px"
                              maxlength="20"
                              show-word-limit></el-input>
                    <el-button size="medium" class="btn-orange" @click="createGroup">创建</el-button>
                </div>
                <el-scrollbar class="group-list-wrapper" v-if="groupList.length > 0">
                    <div class="group-list">
                        <div class="group-item"
                             :class="currentGroupId === groupItem.id ? 'current' : ''"
                             @click="chooseGroupBtn(groupItem)"
                             v-for="(groupItem, groupIndex) in groupList"
                             :key="`groupList_${groupIndex}`">
                            <div class="group-title">{{groupItem.name}}</div>
                            <div class="group-num">商品数量：<span class="orange">{{groupItem.num}}</span></div>
                        </div>
                    </div>
                </el-scrollbar>
                <div class="no-data" v-else>
                    <img :src="require('@/assets/img/image/null-fz.png')" alt="">
                    <div class="text">暂无分组，请新建分组</div>
                </div>
            </div>
            <div slot="footer" style="text-align: center" class="dialog-footer">
                <el-button size="medium" class="btn-orange" @click="addToGroup">加入分组</el-button>
                <el-button size="medium" class="btn-gray" @click="cancelToGroup">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogAddSuccess" width="490px" custom-class="messageDialog">
            <div class="addSuccess-wrapper">
                <i class="iconfont">&#xe60a;</i>
                <div class="text">添加成功</div>
            </div>
            <div slot="footer" style="text-align: center" class="dialog-footer">
                <el-button size="medium" class="btn-orange">
                    <router-link to="/student/mySelectionLibrary" style="display: block; color: #fff">查看选品库</router-link>
                </el-button>
                <el-button size="medium" class="btn-gray" @click="continueBtn">继续选品</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchInput: '',
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                historyList: [
                    {
                        id: 1,
                        keys: '餐具笼',
                        hot: true,
                    },
                    {
                        id: 2,
                        keys: '餐具笼1',
                        hot: false,
                    },
                    {
                        id: 3,
                        keys: '睡衣女',
                        hot: false,
                    },
                    {
                        id: 4,
                        keys: '睡衣女1',
                        hot: true,
                    },
                    {
                        id: 5,
                        keys: '睡衣女2',
                        hot: false,
                    },
                    {
                        id: 6,
                        keys: '睡衣女3',
                        hot: false,
                    },
                ],
                currentTab: 1,
                // 四个list合并
                goodsList: [
                    {
                        id: 1,
                        rank: 1,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 2,
                        rank: 2,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 3,
                        rank: 3,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 4,
                        rank: 4,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 5,
                        rank: 4,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 6,
                        rank: 4,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 7,
                        rank: 4,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 8,
                        rank: 4,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 9,
                        rank: 4,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 10,
                        rank: 4,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 11,
                        rank: 4,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 12,
                        rank: 4,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                ],
                hotGoodsList: [
                    {
                        id: 13,
                        rank: 1,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 14,
                        rank: 2,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                ],
                highCommissionList: [
                    {
                        id: 15,
                        rank: 1,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                ],
                allGoodsList: [
                    {
                        id: 16,
                        rank: 1,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 17,
                        rank: 2,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                    {
                        id: 18,
                        rank: 3,
                        goodCover: 'https://img.alicdn.com/imgextra/i3/1709365317/O1CN01zAmiVp1p9EQiflXnM_!!1709365317-0-daren.jpg_300x300.jpg',
                        addStatus: false, // 是否加入选品库： false 没有加入， true 加入
                        goodName: '铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧铁艺吧台椅酒吧吧台凳北欧',
                        goodLink: 'https://juejin.cn/post/6930792459567890446?utm_source=gold_browser_extension',
                        sales: 3456,
                        price: 80.00,
                        commissionRate: 30,
                        vouchers: 18,
                        storeName: '哈哈旗舰店',
                    },
                ],
                checkedAll: false,
                chooseIdArr: [],

                //S 弹窗
                dialogChooseGroup: false,
                groupName: '',
                currentGroupId: null,
                groupList: [
                    {
                        id: 1,
                        name: '分组1',
                        num: 60,
                    },
                    {
                        id: 2,
                        name: '分组2',
                        num: 660,
                    },
                    {
                        id: 3,
                        name: '分组3',
                        num: 6660,
                    },
                    {
                        id: 4,
                        name: '分组4',
                        num: 66660,
                    },
                    {
                        id: 5,
                        name: '分组5',
                        num: 666660,
                    },
                ],
                dialogAddSuccess: false,
                //E 弹窗
            }
        },
        mounted() {
            this.getGoodsList()
        },
        methods: {
            getGoodsList() {

            },
            // 切换分页s
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getGoodsList()
            },
            // 搜索商品
            searchBtn() {
                this.listPages.currentPageNum = 1
                this.getGoodsList()
                console.log('搜索')
            },
            // 搜索历史词
            chooseSearchKeys(val) {
                this.searchInput = val.keys
                // emmmm 选了之后是不是要搜索下
                // this.searchBtn()
            },
            // 切换榜单tab
            toggleTab(val) {
                this.currentTab = val
            },
            // 单个商品放入选品库
            addSingle(val) {
                val.addStatus = !val.addStatus
                if (val.addStatus) {
                    this.chooseIdArr.push(val.id)
                } else {
                    let currentIndex = this.chooseIdArr.findIndex(item => {
                        return item === val.id
                    })
                    if (currentIndex > -1) {
                        this.chooseIdArr.splice(currentIndex, 1)
                    }
                }
                // 判断列表中有一个没选中，就不全选
                let hasOneNoChoose = this.goodsList.find(item => {
                    return item.addStatus === false
                })
                if (hasOneNoChoose) {
                    this.checkedAll = false
                }
                // 判断列表是否全部选中，是的话就改为全选
                let allChooseArr = this.goodsList.filter(item => {
                    return item.addStatus === true
                })
                if (allChooseArr.length === this.goodsList.length) {
                    this.checkedAll = true
                }
            },
            // 选择全部商品
            checkedAllBtn(val) {
                if (val) {
                    this.goodsList.forEach(item => {
                        item.addStatus = true
                        this.chooseIdArr.push(item.id)
                    })
                    this.chooseIdArr = [...new Set(this.chooseIdArr)] //数组去重
                } else {
                    let idArrTmp = []
                    this.goodsList.forEach(item => {
                        item.addStatus = false
                        idArrTmp.push(item.id)
                    })
                    this.chooseIdArr = this.chooseIdArr.filter(item => {
                        return !idArrTmp.includes(item) // 去掉两个数组相同的值
                    })
                }
            },
            // 加入选品库
            addProductsLibrary() {
                if (this.chooseIdArr.length === 0) {
                    return this.$message.warning('当前没有选择商品，请先添加商品')
                }
                // 获取分组列表
                this.dialogChooseGroup = true
            },
            // 选择分组
            chooseGroupBtn(val) {
                this.currentGroupId = val.id
            },
            // 创建分组
            createGroup() {
                if (this.groupName === '') {
                    return this.$message.warning('请输入分组名称')
                }
                // 创建 获取分组列表咯
                this.groupName = ''
            },
            // 加入分组
            addToGroup() {
                if (!this.currentGroupId) {
                    return this.$message.warning('请选择分组')
                }
                this.dialogChooseGroup = false
                this.dialogAddSuccess = true
            },
            // 取消加入分组
            cancelToGroup() {
                this.dialogChooseGroup = false
            },
            // 继续选品
            continueBtn() {
                this.dialogAddSuccess = false
                this.getGoodsList()
                // 是否需要默认选中推荐榜
            },
            // 关闭选择分组弹窗
            closeChooseGroupDialog() {
                this.currentGroupId = null
            },
        },
    }
</script>

<style scoped lang="scss">
    .main-container {
        position: relative;
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 10px 10px 0 0;
        .main-wrapper {
            height: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            .search-wrapper {
                width: 520px;
                margin: 45px auto 0;
                .search-input {
                    ::v-deep .el-input__inner {
                        color: #222;
                        border-color: #FF7B4B;
                    }
                    ::v-deep .el-input-group__append {
                        background-color: #FE4800;
                        border-color: #FE4800;
                        color: #fff;
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
                .history-keys {
                    margin-top: 12px;
                    width: 100%;
                    height: 20px;
                    overflow: hidden;
                    .keys-item {
                        display: inline-block;
                        margin-right: 20px;
                        color: #222;
                        cursor: pointer;
                        &.current {
                            color: #FE4800;
                        }
                    }
                }
            }
            .list-wrapper {
                height: 1%;
                flex: 1;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                .list-tab {
                    background: #EFF0FF;
                    padding: 0 100px;
                    display: flex;
                    justify-content: space-between;
                    .tab-item {
                        font-size: 16px;
                        line-height: 50px;
                        cursor: pointer;
                        position: relative;
                        transition: all .3s;
                        &.current {
                            font-size: 18px;
                            font-weight: 500;
                            &:before {
                                content: '';
                                position: absolute;
                                left: 50%;
                                bottom: 2px;
                                width: 0;
                                height: 0;
                                transform: translateX(-50%);
                                border-left: 4px solid transparent;
                                border-right: 4px solid transparent;
                                border-bottom: 4px solid #9ACC5B;
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: 2px;
                                background: #9ACC5B;
                            }
                        }
                    }
                }
                .list-container {
                    flex: 1;
                    height: 1%;
                    ::v-deep > .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    .list-content {
                        padding: 10px 100px 16px 50px;
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .good-item {
                        width: calc(16.666666% - 50px);
                        margin: 30px 0 0 50px;
                        position: relative;
                        .good-rank {
                            position: absolute;
                            top: -7px;
                            left: 10px;
                            z-index: 1;
                            color: #FAE46A;
                            display: none;
                            .iconfont {
                                font-size: 58px;
                                color: #EDB151;
                            }
                            .top {
                                position: absolute;
                                top: 6px;
                                width: 62px;
                                text-align: center;
                                font-size: 16px;
                                font-weight: 700;
                            }
                            .num {
                                position: absolute;
                                top: 26px;
                                width: 62px;
                                text-align: center;
                                font-size: 24px;
                                font-weight: 600;
                            }
                        }
                        &:nth-of-type(1) {
                            .good-rank {
                                display: block;
                            }
                        }
                        &:nth-of-type(2) {
                            .good-rank {
                                display: block;
                                color: #E6EEF5;
                                .iconfont {
                                    color: #B2C7D8;
                                }
                            }
                        }
                        &:nth-of-type(3) {
                            .good-rank {
                                display: block;
                                color: #F7D6A6;
                                .iconfont {
                                    color: #D3AD6F;
                                }
                            }
                        }
                        .good-cover {
                            width: 100%;
                            height: 0;
                            padding-bottom: 100%;
                            position: relative;
                            overflow: hidden;
                            background: #EFF0FF;
                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                max-width: 100%;
                                max-height: 100%;
                            }
                            .good-operate {
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                display: flex;
                                .o-item {
                                    width: 50%;
                                    color: #fff;
                                    text-align: center;
                                    line-height: 28px;
                                    background: rgba(255, 82, 0, .8);
                                    position: relative;
                                    cursor: pointer;
                                    font-size: 13px;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        top: 9px;
                                        right: 0;
                                        width: 1px;
                                        height: 10px;
                                        background: #fff;
                                    }
                                    &:last-child {
                                        &:after {
                                            content: none;
                                        }
                                    }
                                    &.gray {
                                        color: #333;
                                        background: rgba(221, 221, 221, .9);
                                    }
                                }
                            }
                        }
                        .good-detail {
                            padding: 12px 7px;
                            box-shadow: 0 0 16px 1px rgba(83, 73, 218, 0.2);
                        }
                        .good-name {
                            color: #222;
                            transition: all .3s;
                            &:hover {
                                color: #FE4800;
                            }
                        }
                        .good-sale {
                            margin: 25px 0;
                            color: #666;
                            text-align: center;
                        }
                        .good-process {
                            ::v-deep .el-progress-bar__outer {
                                background-color: #FFD3C2;
                            }
                        }
                        .good-line {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;
                            .l-item {
                                text-align: center;
                                .line1, .line2 {
                                    line-height: 1;
                                }
                                .line2 {
                                    color: #666;
                                    font-size: 12px;
                                    margin-top: 6px;
                                }
                            }
                        }
                        .good-ticket {
                            margin-top: 8px;
                            display: flex;
                            align-items: center;
                            .ticket-left {
                                width: 22px;
                                background: #EC6941;
                                font-size: 12px;
                                color: #fff;
                                text-align: center;
                                line-height: 20px;
                                border-radius: 2px 0 0 2px;
                            }
                            .right {
                                font-size: 12px;
                                color: #FE4800;
                                border: 1px solid #EC6941;
                                height: 20px;
                                padding: 0 6px;
                                box-sizing: border-box;
                                border-radius: 0 2px 2px 0;
                            }
                        }
                        .store-name {
                            font-size: 12px;
                            margin-top: 12px;
                            text-align: right;
                        }
                    }
                }
            }
        }
        .operate-wrapper {
            height: 80px;
            box-shadow: 0 0 12px 1px rgba(83, 73, 218, 0.2);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 30px;
            .checkAll {
                color: #333;
                font-weight: 400;
                ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner,
                ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
                    background-color: #FF5200;
                    border-color: #FF5200;
                }
                ::v-deep.el-checkbox__input.is-checked + .el-checkbox__label {
                    color: #FF5200;
                }
                ::v-deep.el-checkbox__inner:hover {
                    border-color: #FF5200;
                }
                ::v-deep.el-checkbox__input.is-focus .el-checkbox__inner {
                    border-color: #FF5200;
                }
            }
            .bigBtn {
                display: inline-block;
                width: 200px;
                text-align: center;
                font-size: 18px;
                cursor: pointer;
                line-height: 80px;
                transition: all .3s;
                color: #333;
                &.blue {
                    background: #F1F0FE;
                    &:hover {
                        color: #fff;
                        background: #564CED;
                    }
                }
                &.orange {
                    color: #fff;
                    background: #F9A77F;
                    &:hover {
                        background: #FF5200;
                    }
                }
            }
        }
    }
    .orange {
        color: #FE4800;
    }
    .group-container {
        padding: 20px 0;
        .create-group {
            padding: 0 30px;
            .group-name {
                margin-right: 10px;
                ::v-deep .el-input__inner {
                    border-color: #FE4800;
                }
            }
        }
        .group-list-wrapper {
            margin-top: 20px;
            height: 200px;
            & > ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .group-list {
            display: flex;
            flex-wrap: wrap;
            /*max-height: 200px;*/
            padding: 0 30px;
            margin-top: -20px;
            .group-item {
                width: calc(50% - 20px);
                height: 80px;
                margin-top: 20px;
                background: #F1F0FE;
                border-radius: 4px;
                padding: 20px 16px;
                box-sizing: border-box;
                cursor: pointer;
                transition: all .3s;
                &:nth-of-type(even) {
                    margin-left: 20px;
                }
                &:nth-of-type(odd) {
                    margin-right: 20px;
                }
                .group-title {
                    font-size: 16px;
                    color: #222;
                    line-height: 1;
                }
                .group-num {
                    line-height: 1;
                    color: #666;
                    margin-top: 10px;
                }
                &:hover, &.current {
                    background: #FD815C;
                    .group-title, .group-num, .orange {
                        color: #fff;
                    }
                }
            }
        }
    }
    .no-data {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 30px 0;
        margin-top: 20px;
        img {
            display: block;
            max-height: 100px;
        }
        .text {
            margin: 20px 0 0;
            color: #999;
        }
    }
    .addSuccess-wrapper {
        padding: 70px 0 30px;
        text-align: center;
        .iconfont {
            color: #9acc5a;
            font-size: 62px;
        }
        .text {
            margin-top: 16px;
        }
    }
</style>